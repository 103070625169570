import React from 'react';

const ServicesDesign = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8 42.6401H11.3715C11.2199 42.6401 11.0745 42.5798 10.9673 42.4727C10.8602 42.3655 10.8 42.2202 10.8 42.0686V6.57143C10.8 6.41988 10.8602 6.27457 10.9673 6.16741C11.0745 6.06025 11.2199 6 11.3715 6H22.8C22.9516 6 23.0969 6.06025 23.2041 6.16741C23.3112 6.27457 23.3715 6.41988 23.3715 6.57143V42.0686C23.3715 42.2202 23.3112 42.3655 23.2041 42.4727C23.0969 42.5798 22.9516 42.6401 22.8 42.6401ZM12.0114 41.4743H22.2286V7.18862H12.0114V41.4743Z"
      fill="#475467"
    />
    <path
      d="M22.8002 7.16574H18.0687C17.9339 7.1389 17.8125 7.0661 17.7252 6.95982C17.638 6.85354 17.5903 6.72035 17.5903 6.58287C17.5903 6.44539 17.638 6.31219 17.7252 6.20592C17.8125 6.09964 17.9339 6.02683 18.0687 6H22.8002C22.935 6.02683 23.0563 6.09964 23.1435 6.20592C23.2307 6.31219 23.2784 6.44539 23.2784 6.58287C23.2784 6.72035 23.2307 6.85354 23.1435 6.95982C23.0563 7.0661 22.935 7.1389 22.8002 7.16574Z"
      fill="#475467"
    />
    <path
      d="M22.8002 11.6032H18.0687C17.9339 11.5764 17.8125 11.5036 17.7252 11.3973C17.638 11.291 17.5903 11.1579 17.5903 11.0204C17.5903 10.8829 17.638 10.7497 17.7252 10.6434C17.8125 10.5371 17.9339 10.4643 18.0687 10.4375H22.8002C22.935 10.4643 23.0563 10.5371 23.1435 10.6434C23.2307 10.7497 23.2784 10.8829 23.2784 11.0204C23.2784 11.1579 23.2307 11.291 23.1435 11.3973C23.0563 11.5036 22.935 11.5764 22.8002 11.6032Z"
      fill="#475467"
    />
    <path
      d="M22.8002 16.029H18.0687C17.9339 16.0022 17.8125 15.9294 17.7252 15.8231C17.638 15.7168 17.5903 15.5836 17.5903 15.4461C17.5903 15.3087 17.638 15.1755 17.7252 15.0692C17.8125 14.9629 17.9339 14.8901 18.0687 14.8633H22.8002C22.935 14.8901 23.0563 14.9629 23.1435 15.0692C23.2307 15.1755 23.2784 15.3087 23.2784 15.4461C23.2784 15.5836 23.2307 15.7168 23.1435 15.8231C23.0563 15.9294 22.935 16.0022 22.8002 16.029Z"
      fill="#475467"
    />
    <path
      d="M22.8002 20.4704H18.0687C17.9339 20.4436 17.8125 20.3708 17.7252 20.2645C17.638 20.1582 17.5903 20.025 17.5903 19.8876C17.5903 19.7501 17.638 19.6169 17.7252 19.5106C17.8125 19.4043 17.9339 19.3315 18.0687 19.3047H22.8002C22.935 19.3315 23.0563 19.4043 23.1435 19.5106C23.2307 19.6169 23.2784 19.7501 23.2784 19.8876C23.2784 20.025 23.2307 20.1582 23.1435 20.2645C23.0563 20.3708 22.935 20.4436 22.8002 20.4704Z"
      fill="#475467"
    />
    <path
      d="M22.8002 24.9079H18.0687C17.9339 24.8811 17.8125 24.8083 17.7252 24.702C17.638 24.5957 17.5903 24.4625 17.5903 24.3251C17.5903 24.1876 17.638 24.0544 17.7252 23.9481C17.8125 23.8418 17.9339 23.769 18.0687 23.7422H22.8002C22.935 23.769 23.0563 23.8418 23.1435 23.9481C23.2307 24.0544 23.2784 24.1876 23.2784 24.3251C23.2784 24.4625 23.2307 24.5957 23.1435 24.702C23.0563 24.8083 22.935 24.8811 22.8002 24.9079Z"
      fill="#475467"
    />
    <path
      d="M22.8002 29.3376H18.0687C17.9339 29.3108 17.8125 29.238 17.7252 29.1317C17.638 29.0254 17.5903 28.8922 17.5903 28.7547C17.5903 28.6173 17.638 28.4841 17.7252 28.3778C17.8125 28.2715 17.9339 28.1987 18.0687 28.1719H22.8002C22.935 28.1987 23.0563 28.2715 23.1435 28.3778C23.2307 28.4841 23.2784 28.6173 23.2784 28.7547C23.2784 28.8922 23.2307 29.0254 23.1435 29.1317C23.0563 29.238 22.935 29.3108 22.8002 29.3376Z"
      fill="#475467"
    />
    <path
      d="M22.8002 33.7712H18.0687C17.9172 33.7712 17.7719 33.711 17.6647 33.6038C17.5576 33.4966 17.4973 33.3513 17.4973 33.1998C17.4972 33.046 17.5566 32.8982 17.6632 32.7874C17.7697 32.6766 17.9151 32.6114 18.0687 32.6055H22.8002C22.9538 32.6114 23.0992 32.6766 23.2057 32.7874C23.3123 32.8982 23.3717 33.046 23.3716 33.1998C23.3716 33.3513 23.3114 33.4966 23.2042 33.6038C23.097 33.711 22.9517 33.7712 22.8002 33.7712Z"
      fill="#475467"
    />
    <path
      d="M22.8002 38.2126H18.0687C17.9172 38.2126 17.7719 38.1524 17.6647 38.0452C17.5576 37.938 17.4973 37.7927 17.4973 37.6412C17.4972 37.4875 17.5566 37.3396 17.6632 37.2288C17.7697 37.118 17.9151 37.0528 18.0687 37.0469H22.8002C22.9538 37.0528 23.0992 37.118 23.2057 37.2288C23.3123 37.3396 23.3717 37.4875 23.3716 37.6412C23.3716 37.7927 23.3114 37.938 23.2042 38.0452C23.097 38.1524 22.9517 38.2126 22.8002 38.2126Z"
      fill="#475467"
    />
    <path
      d="M31.4626 12.5655C31.3572 12.5884 31.248 12.5884 31.1426 12.5655L29.4055 11.3998C29.3381 11.3613 29.2793 11.3096 29.2325 11.2477C29.1858 11.1858 29.1521 11.1149 29.1336 11.0396C29.1152 10.9642 29.1122 10.8859 29.125 10.8094C29.1378 10.7328 29.166 10.6598 29.208 10.5945C29.25 10.5293 29.3047 10.4733 29.369 10.4299C29.4333 10.3865 29.5058 10.3565 29.582 10.342C29.6582 10.3275 29.7366 10.3288 29.8123 10.3456C29.8881 10.3625 29.9596 10.3946 30.0226 10.44L31.4626 11.3769L32.8797 10.44C32.977 10.3838 33.0874 10.354 33.1998 10.354C33.3121 10.354 33.4225 10.3838 33.5198 10.44L34.9598 11.3769L36.3769 10.44C36.5042 10.3551 36.6599 10.3243 36.81 10.3543C36.96 10.3843 37.092 10.4725 37.1769 10.5998C37.2618 10.7271 37.2925 10.8831 37.2625 11.0331C37.2325 11.1832 37.1442 11.3149 37.0169 11.3998L35.257 12.5655C35.164 12.622 35.0572 12.652 34.9484 12.652C34.8395 12.652 34.7328 12.622 34.6398 12.5655L33.1998 11.6057L31.7826 12.5655C31.6769 12.5859 31.5683 12.5859 31.4626 12.5655Z"
      fill="#475467"
    />
    <path
      d="M36.7657 36.7874H29.7943C29.6405 36.7875 29.4927 36.7279 29.3819 36.6214C29.2711 36.5148 29.2059 36.3696 29.2 36.216V10.8214C29.2268 10.6866 29.2996 10.5655 29.4059 10.4782C29.5121 10.391 29.6453 10.3432 29.7828 10.3432C29.9203 10.3432 30.0535 10.391 30.1598 10.4782C30.2661 10.5655 30.3389 10.6866 30.3657 10.8214V35.6445H36.1942V10.8214C36.1942 10.6699 36.2545 10.5246 36.3616 10.4174C36.4688 10.3102 36.6141 10.25 36.7657 10.25C36.9172 10.25 37.0625 10.3102 37.1697 10.4174C37.2768 10.5246 37.3371 10.6699 37.3371 10.8214V36.216C37.3371 36.3675 37.2768 36.5128 37.1697 36.62C37.0625 36.7271 36.9172 36.7874 36.7657 36.7874Z"
      fill="#475467"
    />
    <path
      d="M36.7657 42.6387H29.7943C29.6405 42.6388 29.4927 42.5792 29.3819 42.4727C29.2711 42.3661 29.2059 42.2209 29.2 42.0672V36.216C29.2059 36.0623 29.2711 35.9168 29.3819 35.8103C29.4927 35.7037 29.6405 35.6444 29.7943 35.6445H36.7657C36.9172 35.6445 37.0625 35.7045 37.1697 35.8117C37.2768 35.9188 37.3371 36.0644 37.3371 36.216V42.0672C37.3371 42.2188 37.2768 42.3641 37.1697 42.4713C37.0625 42.5784 36.9172 42.6387 36.7657 42.6387ZM30.3657 41.4729H36.1942V36.9015H30.3657V41.4729Z"
      fill="#475467"
    />
    <path
      d="M36.7657 11.3982C36.6827 11.3975 36.6009 11.3787 36.5259 11.3432C36.4509 11.3077 36.3845 11.2563 36.3314 11.1925L33.2685 7.48968L30.2286 11.1925C30.1806 11.2511 30.1214 11.2997 30.0546 11.3354C29.9879 11.3711 29.9147 11.3932 29.8394 11.4007C29.764 11.4081 29.6881 11.4006 29.6156 11.3786C29.5432 11.3567 29.4756 11.3206 29.4171 11.2726C29.3586 11.2246 29.3101 11.1655 29.2744 11.0988C29.2387 11.032 29.2165 10.9587 29.2091 10.8834C29.2016 10.808 29.2091 10.7321 29.2311 10.6596C29.2531 10.5871 29.2891 10.5197 29.3371 10.4612L32.8344 6.20982C32.892 6.14967 32.961 6.10162 33.0376 6.06892C33.1142 6.03621 33.1967 6.01953 33.2799 6.01953C33.3632 6.01953 33.4457 6.03621 33.5223 6.06892C33.5989 6.10162 33.6681 6.14967 33.7257 6.20982L37.2 10.4612C37.2553 10.5145 37.2993 10.5783 37.3293 10.649C37.3594 10.7197 37.3748 10.7957 37.3748 10.8725C37.3748 10.9493 37.3594 11.0253 37.3293 11.096C37.2993 11.1667 37.2553 11.2307 37.2 11.284C37.0722 11.3698 36.9192 11.4099 36.7657 11.3982Z"
      fill="#475467"
    />
    <path
      d="M33.2457 33.7687C33.0919 33.7688 32.9441 33.7092 32.8333 33.6027C32.7225 33.4961 32.6573 33.3509 32.6514 33.1973V14.6144C32.6573 14.4608 32.7225 14.3155 32.8333 14.209C32.9441 14.1024 33.0919 14.0429 33.2457 14.043C33.3972 14.043 33.5425 14.1032 33.6497 14.2104C33.7569 14.3175 33.8171 14.4628 33.8171 14.6144V33.1973C33.8171 33.3488 33.7569 33.4941 33.6497 33.6013C33.5425 33.7084 33.3972 33.7687 33.2457 33.7687Z"
      fill="#475467"
    />
    <path
      d="M36.7655 39.0257H29.7941C29.6425 39.0257 29.4971 38.9654 29.3899 38.8583C29.2828 38.7511 29.2227 38.6058 29.2227 38.4542C29.2227 38.3027 29.2828 38.1574 29.3899 38.0502C29.4971 37.9431 29.6425 37.8828 29.7941 37.8828H36.7655C36.917 37.8828 37.0623 37.9431 37.1695 38.0502C37.2767 38.1574 37.3369 38.3027 37.3369 38.4542C37.3369 38.6058 37.2767 38.7511 37.1695 38.8583C37.0623 38.9654 36.917 39.0257 36.7655 39.0257Z"
      fill="#475467"
    />
  </svg>
);

export default ServicesDesign;
